<template>
  <div>
    <ul>
      <li>
        <img src="../../../assets/user.svg" alt="" />
        <span class="name">Amanda Miles</span>
        <span class="dots">...</span>
      </li>
      <li>
        <img src="../../../assets/user.svg" alt="" />
        <span class="name">Melissa Byron</span>
        <span class="dots">...</span>
      </li>
      <li>
        <img src="../../../assets/user.svg" alt="" />
        <span class="name">Amanda Miles</span>
        <span class="dots">...</span>
      </li>
      <li>
        <img src="../../../assets/user.svg" alt="" />
        <span class="name">Melissa Byron</span>
        <span class="dots">...</span>
      </li>
      <li>
        <img src="../../../assets/user.svg" alt="" />
        <span class="name">Amanda Miles</span>
        <span class="dots">...</span>
      </li>
      <li>
        <img src="../../../assets/user.svg" alt="" />
        <span class="name">Melissa Byron</span>
        <span class="dots">...</span>
      </li>
      <li>
        <img src="../../../assets/user.svg" alt="" />
        <span class="name">Amanda Miles</span>
        <span class="dots">...</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Contacts",
};
</script>

<style scoped>
div {
  background: white;
  border-radius: 5px;
}
li {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 10px;
}
img {
  height: 40px;
}
.name {
  margin: 0 10px;
  flex: 1;
  text-align: left;
}
</style>