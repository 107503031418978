<template>
<div class="container">
    <div class="image__con">
        <img src="../../../assets/user.svg" alt="">
    </div>
    <div class="text__con">
        <div class="heading">Alexandra Burke</div>
        <div class="text">@alexsunshine</div>
    </div>
</div>
</template>

<script>
export default {
  name: "User",
};
</script>

<style scoped>
.container{
    padding: 8px;
    display: flex;
    background: white;
    border-radius: 5px;
    justify-content: space-around;
    align-items: center;
}
.image__con{
    display: flex;
    align-items: center;
}
.image__con > img {
    height: 50px;
    border-radius: 5px;
}
.heading{
    font-weight: 700;
}
</style>