<template>
  <div class="container">
    <div class="write__con">
      <div class="image__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="input__con">
        <input type="text" placeholder="What's new Alexandra" />
      </div>
      <div class="button__con">
        <button>Post it!</button>
      </div>
    </div>
    <div class="posts__con">
      <div class="user__con">
        <div class="image__con">
          <img src="../../../assets/user.svg" alt="" />
        </div>
        <div class="text__con">
          <div class="heading">Laura Fisher</div>
          <div class="text">12 hours ago</div>
        </div>
      </div>
      <div class="comment">
        This was one of the most epic journeys, that I've got myself involved
        in. Maybe one of the most memorable in my entire life!
      </div>
      <div class="icons__con">
        <div class="icon__con">
          <img src="../../../assets/mountain.png" alt="" />
        </div>
        <div class="icon__con">
          <img src="../../../assets/mountain.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Status",
};
</script>

<style scoped>
.container {
  background: white;
  padding: 1em;
  border-radius: 5px;
  text-align: left;
}
.write__con {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.image__con {
  display: flex;
  align-items: center;
}
.image__con > img {
  height: 40px;
  border-radius: 5px;
}
.input__con {
  flex: 1;
  margin: 0 5px;
  display: flex;
}
.input__con > input {
  flex: 1;
  border: none;
  padding: 0.5em 1em;
  background: rgb(252, 251, 251);
  border-radius: 10px;
  outline: thin;
}
.button__con > button {
  padding: 8px 16px;
  background: rgb(8, 86, 187);
  color: white;
  border: none;
  border-radius: 5px;
}
.posts__con {
  background: rgb(245, 242, 242);
  padding: 1em;
  border-radius: 5px;
}
.user__con {
  display: flex;
  align-items: center;
}
.text__con {
  margin-left: 10px;
  font-size: 12px;
}
.heading {
  font-size: 13px;
  font-weight: 700;
}
.comment {
  font-size: 15px;
  margin: 0.8em 0;
}
.icons__con {
  display: flex;
  justify-content: space-around;
  margin: 0.8em 0;
}
.icon__con {
  max-width: 350px;
  margin: 0 10px;
}
.icon__con > img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .icons__con {
    flex-direction: column;
    align-items: center;
  }
  .button__con > button {
    display: none;
  }
}
</style>