<template>
  <div class="container">
    <img src="../../../assets/company.png" alt="" />
    <div>
      <button>Accept Invitation</button>
      <div class="close">X</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Invitation",
};
</script>

<style scoped>
.container {
  background: white;
  padding: 1em;
  border-radius: 5px;
  margin-top: 10px;
}
.container > img {
  height: 200px;
  border-radius: 10px;
}
.container > div {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
button {
  padding: 5px 10px;
  background: rgb(8, 86, 187);
  color: white;
  border: none;
  border-radius: 5px;
}
.close {
  border-radius: 5px;
  padding: 5px 8px;
  border: 1px solid rgb(214, 206, 206);
}
@media (max-width: 1200px) {
  .container > img {
    height: 150px;
    border-radius: 10px;
  }
  button {
    padding: 5px 6px;
  }
}
</style>