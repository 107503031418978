<template>
  <div class="container">
    <span class="bold">Popular</span> This Week
    <div class="icons__con">
      <div class="icon__con">
        <img src="../../../assets/scenes.png" alt="" />
      </div>
      <div class="icon__con">
        <img src="../../../assets/scenes.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popular",
};
</script>

<style scoped>
.container {
  background: white;
  padding: 1em;
  border-radius: 5px;
  text-align: left;
}
.bold {
  font-weight: 700;
}
.icons__con {
  display: flex;
  justify-content: space-around;
  margin: 0.8em 0;
}
.icon__con {
  max-width: 350px;
  margin: 0 10px;
}
.icon__con > img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .icons__con {
    flex-direction: column;
    align-items: center;
  }
  .icon__con {
    max-width: 250px;
  }
}
</style>