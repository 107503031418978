<template>
  <div class="friend__section">
    <div class="details__con">
      <div class="image__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="text__con">
        <span class="name">Tyrell Barrows</span> wants to add you to friends
      </div>
    </div>
    <div class="cta__section">
      <button class="accept">Accept</button>
      <button class="decline">Decline</button>
    </div>
  </div>
  <div class="friend__section">
    <div class="details__con">
      <div class="image__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="text__con">
        <span class="name">Selena Gomez</span> wants to add you to friends
      </div>
    </div>
    <div class="cta__section">
      <button class="accept">Accept</button>
      <button class="decline">Decline</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Friends",
};
</script>

<style scoped>
.friend__section {
  background: white;
  border-radius: 5px;
  padding: 1em;
  margin: 0.7em 0;
}
.details__con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.image__con {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.image__con > img {
  height: 40px;
  border-radius: 5px;
}
.name {
  font-weight: 700;
}
.cta__section {
  margin: 1em 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button {
  padding: 6px 12px;
  border-radius: 10px;
  flex: 1;
  margin: 0 5px;
}
.accept {
  background: blue;
  color: white;
  border: none;
}
.decline {
  border: 1px solid rgb(214, 206, 206);
}
</style>