<template>
  <div class="requests">
    <p>REQUESTS</p>
    <p class="number">2</p>
  </div>
  <div>
    <Friends />
  </div>
  <div class="contacts">
    <p>CONTACTS</p>
    <p class="number">68</p>
  </div>
  <div>
      <Contacts />
  </div>
</template>

<script>
import Friends from "./friends";
import Contacts from "./contacts";

export default {
  name: "Request",
  components: {
    Contacts,
    Friends,
  },
};
</script>

<style scoped>
.requests,
.contacts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.8rem;
}
.number {
  padding: 3px 6px;
  border-radius: 50%;
  background: rgb(26, 99, 194);
  color: white;
}
.contacts{
    margin: 3em 0 1em;
}
.contacts > .number {
  background: rgb(104, 100, 100);
  padding: 5px 8px;
}
</style>