<template>
  <div>
    <User />
  </div>
  <div class="list__con">
    <List />
  </div>
  <div class="invitations">
    <p>INVITATIONS</p>
    <p class="number">4</p>
  </div>
  <div>
    <Invitation />
  </div>
</template>

<script>
import User from "./user";
import List from "./list";
import Invitation from "./invitation";

export default {
  name: "Profile",
  components: {
    User,
    List,
    Invitation,
  },
};
</script>

<style scoped>
.list__con {
  margin: 1em 0;
}
.invitations {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.number {
  padding: 2px 5px;
  border-radius: 50%;
  background: crimson;
  color: white;
}
</style>