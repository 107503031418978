<template>
  <main class="main__container">
    <section class="profile__section">
      <Profile />
    </section>
    <section class="feed__section">
      <Feed />
    </section>
    <section class="requests__section">
      <Request />
    </section>
  </main>
</template>

<script>
import Profile from "./Profile/profile";
import Feed from "./Feed/feed";
import Request from "./Request/request";

export default {
  name: "Main",
  components: {
    Profile,
    Feed,
    Request,
  },
};
</script>

<style scoped>
.main__container {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  gap: 15px;
  padding: 1em;
}
@media (max-width: 1024px) {
  .main__container {
    grid-template-columns: 1fr;
  }
  .profile__section,
  .requests__section {
    display: none;
  }
}
</style>