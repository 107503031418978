<template>
  <Navbar/>
  <Main/>
</template>

<script>
import Navbar from './components/Navbar/navbar'
import Main from './components/Main/main'

export default {
  name: 'App',
  components: {
    Navbar,
    Main
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(207, 204, 204);
  max-width: 1900px;
  margin: 0 auto;
  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
