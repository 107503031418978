<template>
  <nav class="container">
    <div class="logo__con">Logo</div>
    <div class="tabs__con">
        <div class="tab__con">Invitations</div>
        <div class="tab__con">Events</div>
        <div class="tab__con">My events</div>
    </div>
    <div class="icons__con">
      <div class="bell__con">
          <img src="../../assets/bell.svg" alt="">
      </div>
      <div class="create__con">
          <button class="create">Create</button>
      </div>
      <div class="image__con">
          <img src="../../assets/user.svg" alt="">
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped>
.container {
  display: flex;
  background: white;
  padding: 1em;
}
.tabs__con{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    border-bottom: 0.5px solid rgb(240, 234, 234);
}
.tab__con {
    padding: 0 1em;
    padding-bottom: 10px;
}
.tab__con:hover {
    border-bottom: 2px solid rgb(26, 99, 194);
}
.icons__con{
    display: flex;
}
.icons__con > div {
    margin: 0 0.5em;
}
.create{
    background: blue;
    padding: 6px 12px;
    border-radius: 5px;
    color: white;
    border: none;
}
.image__con > img {
    border-radius: 5px;
    height: 30px;
}
@media (max-width: 576px) {
  .icons__con, .tabs__con {
    display: none;
  }
}
</style>