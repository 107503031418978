<template>
  <div class="container">
    <span class="bold">Top</span> Tutors
    <div class="icons__con">
      <div class="icon__con">
        <div class="image__con">
          <img src="../../../assets/user.svg" alt="" />
        </div>
        <div class="text__con">
          <div class="heading">Alexandra Burke</div>
          <div class="text">@alexsunshine</div>
        </div>
        <div class="plus">+</div>
      </div>
      <div class="icon__con">
        <div class="image__con">
          <img src="../../../assets/user.svg" alt="" />
        </div>
        <div class="text__con">
          <div class="heading">Alexandra Burke</div>
          <div class="text">@alexsunshine</div>
        </div>
        <div class="plus">+</div>
      </div>
      <div class="icon__con">
        <div class="image__con">
          <img src="../../../assets/user.svg" alt="" />
        </div>
        <div class="text__con">
          <div class="heading">Alexandra Burke</div>
          <div class="text">@alexsunshine</div>
        </div>
        <div class="plus">+</div>
      </div>
      <div class="icon__con">
        <div class="image__con">
          <img src="../../../assets/user.svg" alt="" />
        </div>
        <div class="text__con">
          <div class="heading">Alexandra Burke</div>
          <div class="text">@alexsunshine</div>
        </div>
        <div class="plus">+</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tutors",
};
</script>

<style scoped>
.container {
  background: white;
  padding: 1em;
  border-radius: 5px;
  text-align: left;
}
.bold {
  font-weight: 700;
}
.icons__con {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.icon__con {
  padding: 10px;
  border-radius: 5px;
  background: grey;
  display: flex;
  align-items: center;
}
.image__con {
  display: flex;
  align-items: center;
}
.image__con > img {
  height: 40px;
  border-radius: 5px;
}
.text__con {
  flex: 1;
  margin: 0 5px;
}
.heading {
  font-weight: 700;
}
.plus {
  padding: 2px 5px;
  border-radius: 50%;
  background: white;
}
@media (max-width: 576px) {
  .icons__con {
    grid-template-columns: 1fr;
  }
}
</style>