<template>
  <div>
    <Streaming />
  </div>
  <div>
    <Popular />
  </div>
  <div>
    <Tutors />
  </div>
  <div>
    <Status />
  </div>
</template>

<script>
import Streaming from "./streaming";
import Popular from "./popular";
import Tutors from "./tutors";
import Status from "./status";

export default {
  name: "Feed",
  components: {
    Streaming,
    Popular,
    Tutors,
    Status,
  },
};
</script>

<style scoped>
div {
  margin: 1em 0;
}
</style>