<template>
  <div class="container">
    <span class="bold">Streaming</span> Now
    <div class="icons__con">
      <div class="icon__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="icon__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="icon__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="icon__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="icon__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="icon__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
      <div class="icon__con">
        <img src="../../../assets/user.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Streaming",
};
</script>

<style scoped>
.container {
  background: white;
  padding: 1em;
  border-radius: 5px;
  text-align: left;
}
.bold {
  font-weight: 700;
}
.icons__con {
  display: flex;
  flex-wrap: wrap;
  margin: 0.8em 0;
}
.icon__con {
  padding: 2px;
  border-radius: 15px;
  border: 1px solid red;
  margin: 0.2em 1em 0 0;
}
</style>