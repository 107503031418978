<template>
  <div>
    <ul>
      <li>
        <img src="../../../assets/bell.svg" alt="" />
        <span>Home</span>
      </li>
      <li>
        <img src="../../../assets/bell.svg" alt="" />
        <span>People</span>
      </li>
      <li>
        <img src="../../../assets/bell.svg" alt="" />
        <span>Photos</span>
      </li>
      <li>
        <img src="../../../assets/bell.svg" alt="" />
        <span>News Feed</span>
      </li>
      <li>
        <img src="../../../assets/bell.svg" alt="" />
        <span>Profile</span>
      </li>
      <li>
        <img src="../../../assets/bell.svg" alt="" />
        <span>Settings</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "List",
};
</script>

<style scoped>
div {
  background: white;
  border-radius: 5px;
}
li {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 10px;
}
li:hover{
    border-left: 2px solid rgb(26, 99, 194) ;
}
img{
    height: 25px;
}
span {
  margin: 0 10px;
}
</style>